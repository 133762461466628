/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, useInView, AnimatePresence, cubicBezier, useAnimate, stagger } from 'framer-motion';

import { useRef, useState, useEffect, useContext } from 'react';

import { useInViewScroll, MotionImage, useSanitizedTranslation, XumoButton as Button, StackedContent, RemoteBlock, TextBlock, Banner } from 'ui';
import { useMeasure, useWindowSize } from '@uidotdev/usehooks';
import { PageContext } from 'ui/contexts';
import { NewsBlock } from '.';
import classNames from 'classnames';
import { getImageProps } from 'next/image';

function getElementTopPosition(elem) {
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return top;
}

//export type HeroProps = {};

function wrapWordsWithSpan(htmlString) {
  // Parse the HTML string into a document object
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Recursive function to process each node
  function processNode(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      // Split text into words and wrap each with a span
      const wrappedText = node.textContent.replace(/([\w\p{P}']+)/gu, '<span>$1</span>');
      const fragment = document.createRange().createContextualFragment(wrappedText);
      node.parentNode.replaceChild(fragment, node);
    } else {
      // Process each child node
      Array.from(node.childNodes).forEach(processNode);
    }
  }

  // Start processing from the body element
  processNode(doc.body);

  // Return the modified HTML as a string
  return doc.body.innerHTML;
}

const DesktopHero = ({}) => {
  const { t } = useSanitizedTranslation();

  const { headerHeight } = useContext(PageContext);
  const [heroRef, setHeroRef] = useState();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [wrapperRef, { width: initialWidth, height: initialHeight }] = useMeasure();
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (heroRef) {
      setOffsetTop(getElementTopPosition(heroRef));
    }
  }, [heroRef, setOffsetTop, windowHeight, windowWidth]);

  const scale = Math.max(((windowHeight || 1) - headerHeight) / ((initialHeight || 1) - headerHeight), (windowWidth || 1) / (initialWidth || 1));

  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100px', 'end 0vh']);

  const containerScale = useTransform(amt, [0, 0.4], [1.01, scale], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const heroPosition = useTransform(amt, [0, 0.3], [0, -offsetTop + 30 + headerHeight], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const imageY = useTransform(amt, [0, 1.0], ['0%', '-5%'], { ease: cubicBezier(0.35, 0, 0.44, 1) });
  const imageOpacity = useTransform(amt, [0.5, 0.8], [1.0, 0], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const titleY = useTransform(amt, [0, 0.5], [0, -75]);

  const titleScale = useTransform(amt, [0, 0.5], [1, 0.9]);

  const [scope, animate] = useAnimate();
  const [bodyRef, bodyAnimate] = useAnimate();

  const bodyInView = useInView(bodyRef, { margin: '-30% 0% -30% 0%', once: true });

  useEffect(() => {
    animate(
      '.logo-img, h1 strong, h1 span',
      {
        y: [20, 0],
        opacity: [0, 1],
      },
      {
        ease: [0, 1, 1, 1],
        duration: 0.9,
        delay: stagger(0.075),
        deltaRest: 0.00001,
        deltaSpeed: 0.00001,
      },
    );
  }, []);

  const title = '<span>Stream</span> <strong>easy</strong>';

  return (
    <>
      <motion.section ref={ref} style={{ opacity: imageOpacity }} className="w-full bg-white" data-testid={'keyart-section'}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ top: headerHeight, opacity: 1, transition: { duration: 0.2 } }}
          ref={scope}
          className="wrapper sticky h-0"
        >
          <div className="py-8 xl:py-16">
            <MotionImage
              priority
              style={{ y: titleY, scale: titleScale }}
              height={41}
              width={237}
              className="logo-img mx-auto h-[36px] w-auto"
              src="/static/images/refresh/xumo-tv-logo.png"
              alt=""
            />
            <motion.h1
              style={{ y: titleY, scale: titleScale }}
              className="type-title m-0 text-center [&>*]:inline-block"
              data-i18n
              dangerouslySetInnerHTML={{ __html: t(title) }}
            />
          </div>
          <motion.div style={{ y: heroPosition }} ref={wrapperRef} className="aspect-[2560/1282] origin-top">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ ease: [0, 1, 1, 1], delay: 0.55, duration: 0.6 }}
              className="relative"
            >
              <motion.div
                key="hero"
                ref={setHeroRef}
                style={{ opacity: 1, scale: containerScale }}
                className="relative flex aspect-[2560/1282] w-full origin-top items-center justify-center overflow-clip rounded-2xl bg-xumoIce"
              >
                <MotionImage
                  style={{ y: imageY, opacity: 1 }}
                  src="/static/images/refresh/xumo-tv-hero-alt.jpg"
                  priority
                  fill
                  sizes="150vw"
                  className="top-0 h-[105%] w-full object-cover object-bottom"
                />
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
        <div className="h-[250vh]" />
      </motion.section>
      <div className="relative z-40 -mt-[50vh] h-[400px] w-full" style={{ background: 'linear-gradient(to top, white 0%, rgba(255,255,255,0) 100%)' }} />
    </>
  );
};

const MobileHero = ({}) => {
  const { t } = useSanitizedTranslation();

  const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100px', 'end 0vh']);

  const imageY = useTransform(amt, [0, 1.0], ['0%', '-5%'], { ease: cubicBezier(0.35, 0, 0.44, 1) });

  const titleY = useTransform(amt, [0, 0.5], [0, 50]);

  const titleScale = useTransform(amt, [0, 0.5], [1, 0.9]);

  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      '.logo-img, h1 strong, h1 span',
      {
        y: [20, 0],
        opacity: [0, 1],
      },
      {
        ease: [0, 1, 1, 1],
        duration: 0.9,
        delay: stagger(0.075),
        deltaRest: 0.00001,
        deltaSpeed: 0.00001,
      },
    );
  }, []);

  const title = '<span>Stream</span> <strong>easy</strong>';

  return (
    <>
      <motion.section ref={ref} className="w-full bg-white" data-testid={'keyart-section'}>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.2 } }} ref={scope} className="wrapper">
          <div className="py-8 xl:py-16">
            <MotionImage
              priority
              style={{ y: titleY }}
              height={41 * 0.8}
              width={237 * 0.8}
              className="logo-img mx-auto mb-4 h-[28px] w-auto"
              src="/static/images/refresh/xumo-tv-logo-horiz.svg"
              alt=""
            />
            <motion.h1
              style={{ y: titleY, scale: titleScale }}
              className="type-title m-0 mt-2 text-center [&>*]:inline-block"
              data-i18n
              dangerouslySetInnerHTML={{ __html: t(title) }}
            />
          </div>
          <motion.div className="aspect-[2560/2400] origin-top md:aspect-[2560/2000] lg:aspect-[2560/1282]">
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ ease: [0, 1, 1, 1], delay: 0.55, duration: 0.6 }}
              className="relative"
            >
              <motion.div
                key="hero"
                style={{ opacity: 1 }}
                className="relative flex aspect-[2560/2400] w-full origin-top items-center justify-center overflow-clip rounded-2xl bg-xumoIce md:aspect-[2560/2000] lg:aspect-[2560/1282]"
              >
                <MotionImage
                  style={{ y: imageY, opacity: 1 }}
                  src="/static/images/refresh/xumo-tv-hero-alt.jpg"
                  priority
                  fill
                  sizes="150vw"
                  className="top-0 h-[105%] w-full object-cover object-bottom"
                />
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.section>
    </>
  );
};

export const TvHero = ({}) => {
  const { t } = useSanitizedTranslation();

  const [wasMobile, setWasMobile] = useState(typeof window === 'undefined');
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const [bodyRef, bodyAnimate] = useAnimate();

  const bodyInView = useInView(bodyRef, { margin: '-30% 0% -30% 0%', once: true });

  useEffect(() => {
    if (!wasMobile && windowWidth !== null && windowWidth <= 768) {
      setWasMobile(true);
    }
  }, [wasMobile, windowWidth]);

  useEffect(() => {
    if (bodyInView) {
      bodyAnimate(
        '.body-ref span, a',
        {
          y: [40, 0],
          opacity: [0, 1],
        },
        {
          ease: [0, 1, 1, 1],
          duration: 0.9,
          delay: stagger(0.03),
          deltaRest: 0.00001,
          deltaSpeed: 0.00001,
        },
      );
    }
  }, [bodyInView]);

  const body = '<strong>Xumo TV is a smart TV experience</strong> that simplifies streaming so you can spend more time watching and less time app hopping.';

  const [formattedBody, setFormattedBody] = useState('');

  useEffect(() => {
    if (!formattedBody) {
      setFormattedBody(wrapWordsWithSpan(body));
    }
  }, [body]);

  return (
    <>
      {wasMobile ? <MobileHero /> : <DesktopHero />}
      <motion.div className="relative z-40 w-full bg-white">
        <motion.div
          ref={bodyRef}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, margin: '-30% 0% -30% 0%' }}
          className="wrapper relative w-full"
        >
          <motion.div
            className="body-ref content-wrapper type-heading-alt pt-8 text-center [&>i>span]:inline-block [&>span]:inline-block  [&>strong>span]:inline-block"
            data-i18n
            dangerouslySetInnerHTML={{ __html: t(formattedBody) }}
          />
          <div className="flex justify-center  pb-16 pt-8 text-center leading-tight tracking-tight" data-i18n>
            <Button href="#reel">Watch the video</Button>
          </div>
        </motion.div>
      </motion.div>
      <TextBlock bg="" content={[{ heading: '<strong>Streaming is better</strong><br/> on Xumo&nbsp;TV' }]} />
      <StickyTv
        content={[
          [
            { subheading: 'One destination for entertainment' },
            { bodySmall: '250+ must-watch apps come together on one simple platform that makes sense of streaming.' },
          ],
          [{ subheading: 'Universal search' }, { bodySmall: 'Search across all apps at once to find must-see movies and TV shows — without the app hopping.' }],
          [
            { subheading: 'Curate with My List' },
            { bodySmall: 'Access movies and TV shows from top apps all in one place with your own personalized watchlist.' },
          ],
          [{ subheading: 'Scroll less, watch&nbsp;more' }, { bodySmall: 'Discover new and trending entertainment right from your home&nbsp;screen.' }],
        ]}
      />

      <RemoteBlock
        tvRemote
        content={`
        <h2 class="type-heading-alt pb-4 m-0">Say the word</h2>
        <p class="type-body">Our advanced voice remote features Emmy award-winning voice technology so you can find your next watch faster, navigate easier and stream even better.</p>
    `}
      />

      <TileScroller bg="bg-[#f5f7f8] rounded-3xl" />
    </>
  );
};

{
  /* <div id="peacock-promo" className="-top-[100px] relative"/>
  <Promo DEBUG */
}
{
  /* <ReasonsToBuy  DEBUG */
}

{
  /* <PartnerTiles  CMS */
}

{
  /* <FAQ CMS */
}

const StickyBlock = ({ item, trigger, state, index, states }) => {
  const ref = useRef();
  const isInView = useInView(ref, { amount: 0, margin: '-40% 0% -40% 0%' });

  useEffect(() => {
    if (state !== index && isInView) {
      trigger(index);
    }
  }, [isInView, state, trigger]);

  const { t } = useSanitizedTranslation();
  return (
    <div ref={ref} className="relative z-10 flex flex-col items-center text-center max-lg:py-8 lg:-translate-y-1/2 last-of-type:lg:translate-y-1/4">
      <motion.div
        style={{
          willChange: 'filter',
          filter: 'drop-shadow(10px 10px 3px rgba(0,0,0,0.15)) drop-shadow(1px 2px 2px rgba(0,0,0,0.75))',
        }}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ zIndex: 10, y: 0, opacity: 1, transition: { ease: 'easeOut', duration: 0.25, restDelta: 0.0001, restSpeed: 0.0001 } }}
        viewport={{ amount: 0, margin: '-10% 0% -10% 0%', once: true }}
        className="relative mb-16 aspect-[2560/1482] w-full lg:hidden"
      >
        <MotionImage
          style={{ opacity: 1 }}
          alt=""
          src="/static/images/refresh/xumo-tv-frame.png"
          priority
          fill
          sizes="100vw"
          className="h-full w-full object-cover"
        />
        <div className="absolute left-[1%] top-[1.5%] aspect-video w-[98%]">
          <MotionImage fill sizes="100vw" className="h-full w-full object-cover" alt="" key={'tvsticky-mobile' + index} src={states[index]} />
        </div>
      </motion.div>

      <motion.div id={'sticky' + index}>
        <StackedContent margin="-10% 0% -10% 0%" noOrphans center content={item} />
      </motion.div>
    </div>
  );
};

const StickyTv = ({ flipped, content, bg = '' }) => {
  const { t } = useSanitizedTranslation();
  const [activeState, setState] = useState(0);

  const states = [
    '/static/images/refresh/ui-home.png',
    '/static/images/refresh/xumo-tv-search-q1-25.jpg',
    '/static/images/refresh/xumo-tv-mylist-q1-25.jpg',
    '/static/images/refresh/xumo-tv-discovery-q1-25.jpg',
  ];

  const imageProps = states.map(state => getImageProps(state));

  return (
    <motion.section className="sw-full relative z-40 items-center bg-white pb-16" data-testid={'text-section'}>
      <div className="absolute -left-[9999px]">
        <MotionImage fill sizes="100vw" className="h-full w-full object-cover" alt="" loading="eager" src={states[1]} />

        <MotionImage fill sizes="100vw" className="h-full w-full object-cover" alt="" loading="eager" src={states[2]} />

        <MotionImage fill sizes="100vw" className="h-full w-full object-cover" alt="" loading="eager" src={states[3]} />
      </div>
      <div className="wrapper">
        <div className="content-wrapper-x mb-16 flex flex-wrap items-stretch justify-between gap-4 lg:mb-32 lg:pb-[150px] xl:pb-[200px] 2xl:pb-[240px]">
          <button
            onClick={() => window.scrollTo(0, getElementTopPosition(document.getElementById('sticky0')) - (window.innerHeight / 2 - 60) - 25)}
            className="group flex grow basis-1/3 cursor-pointer flex-col items-center justify-center rounded-xl p-2 text-center lg:basis-0 lg:space-y-8"
          >
            <div className="aspect-square w-[30px] lg:w-[55px]">
              <img src="/static/images/refresh/icon-grid.svg" className="h-full w-full" />
            </div>
            <p className="type-body-small flex grow text-center max-lg:my-4">
              Stream 250+
              <br /> top apps
            </p>

            <svg
              width="24"
              height="24"
              viewBox="0 0 38 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative -top-2 -scale-y-100 transition-transform group-hover:translate-y-[.25rem] [&>path]:transition-colors group-hover:[&>path]:stroke-xumoTeal"
            >
              <path d="M34.5278 20.0776L18.3585 4.33431L2.74039 20.6754" stroke="black" strokeWidth="5" strokeLinecap="round" />
            </svg>
          </button>

          <button
            onClick={() => window.scrollTo(0, getElementTopPosition(document.getElementById('sticky1')) - (window.innerHeight / 2 - 60) - 25)}
            className="group flex grow basis-1/3 flex-col items-center justify-center rounded-xl p-2 text-center lg:basis-0 lg:space-y-8"
          >
            <div className="aspect-square w-[30px] lg:w-[55px]">
              <img src="/static/images/refresh/icon-search.svg" className="h-full w-full" />
            </div>
            <p className="type-body-small flex grow text-center max-lg:my-4">
              Search all apps
              <br /> at once
            </p>

            <svg
              width="24"
              height="24"
              viewBox="0 0 38 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative -top-2 -scale-y-100 transition-transform group-hover:translate-y-[.25rem] [&>path]:transition-colors group-hover:[&>path]:stroke-xumoTeal"
            >
              <path d="M34.5278 20.0776L18.3585 4.33431L2.74039 20.6754" stroke="black" strokeWidth="5" strokeLinecap="round" />
            </svg>
          </button>
          <button
            onClick={() => window.scrollTo(0, getElementTopPosition(document.getElementById('sticky2')) - (window.innerHeight / 2 - 60) - 25)}
            className="group flex grow basis-1/3 flex-col items-center justify-center rounded-xl p-2 text-center lg:basis-0 lg:space-y-8"
          >
            <div className="aspect-square w-[30px] lg:w-[55px]">
              <img src="/static/images/refresh/icon-star.svg" className="h-full w-full" />
            </div>
            <p className="type-body-small flex grow text-center max-lg:my-4">
              Curate a <br /> watchlist
            </p>

            <svg
              width="24"
              height="24"
              viewBox="0 0 38 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative -top-2 -scale-y-100 transition-transform group-hover:translate-y-[.25rem] [&>path]:transition-colors group-hover:[&>path]:stroke-xumoTeal"
            >
              <path d="M34.5278 20.0776L18.3585 4.33431L2.74039 20.6754" stroke="black" strokeWidth="5" strokeLinecap="round" />
            </svg>
          </button>
          <button
            onClick={() => window.scrollTo(0, getElementTopPosition(document.getElementById('sticky3')) - (window.innerHeight / 2 - 60) - 25)}
            className="group flex grow basis-1/3 flex-col items-center justify-center rounded-xl p-2 text-center lg:basis-0 lg:space-y-8"
          >
            <div className="aspect-square w-[30px] lg:w-[55px]">
              <img src="/static/images/refresh/icon-watch.svg" className="h-full w-full" />
            </div>
            <p className="type-body-small flex grow text-center max-lg:my-4">
              Scroll less,
              <br />
              watch more
            </p>

            <svg
              width="24"
              height="24"
              viewBox="0 0 38 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative -top-2 -scale-y-100 transition-transform group-hover:translate-y-[.25rem] [&>path]:transition-colors group-hover:[&>path]:stroke-xumoTeal"
            >
              <path d="M34.5278 20.0776L18.3585 4.33431L2.74039 20.6754" stroke="black" strokeWidth="5" strokeLinecap="round" />
            </svg>
          </button>
        </div>
      </div>
      <div className="wrapper lg:-mb-[150px] xl:-mb-[200px] 2xl:-mb-[240px]">
        <div className="content-wrapper-x">
          <div className="flex gap-8">
            <div className={`flex items-start justify-center max-lg:hidden lg:basis-3/5`}>
              <div className="top-[calc(50vh+60px)] flex w-full items-center justify-center lg:sticky">
                <motion.div
                  style={{
                    filter: 'drop-shadow(10px 10px 3px rgba(0,0,0,0.15)) drop-shadow(1px 2px 2px rgba(0,0,0,0.75))',
                  }}
                  className="relative aspect-[2560/1482] lg:-left-[15%] lg:min-w-[120%] lg:-translate-y-1/2"
                >
                  <MotionImage
                    style={{ opacity: 1 }}
                    alt=""
                    src="/static/images/refresh/xumo-tv-frame.png"
                    priority
                    fill
                    sizes="100vw"
                    className="h-full w-full object-cover"
                  />
                  <div className="absolute left-[1%] top-[1.5%] aspect-video w-[98%]">
                    <AnimatePresence>
                      <MotionImage
                        fill
                        sizes="100vw"
                        className="h-full w-full object-cover"
                        alt=""
                        key={'tvsticky' + activeState}
                        src={states[activeState]}
                        initial={{ opacity: 0 }}
                        animate={{ zIndex: 10, opacity: 1, transition: { ease: 'easeOut', duration: 0.25, restDelta: 0.0001, restSpeed: 0.0001 } }}
                        exit={{ opacity: 1, zIndex: 1, transition: { delay: 0.25, duration: 0.05 } }}
                      />
                    </AnimatePresence>
                  </div>
                </motion.div>
              </div>
            </div>

            <div className="relative z-10 flex w-full flex-col items-center text-center lg:basis-2/5 lg:space-y-[50vh] lg:pb-[300px] lg:pl-8 xl:pb-[400px] 2xl:pb-[480px]">
              {content.map((item, index) => (
                <StickyBlock states={states} key={'tv-sticky-' + index} item={item} state={activeState} index={index} trigger={() => setState(index)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

const TileScroller = ({ content = '', bg }) => {
  const { ref, progress } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);

  //const progress = useSpring(amt, { stiffness: 400, damping: 90 });
  const scrollX = useTransform(progress, [0, 1], ['-15vw', '15vw']);
  const scrollXAlt = useTransform(progress, [0, 1], ['15vw', '-15vw']);

  const variants = {
    visible: {
      transition: {
        delayChildren: 0,
        staggerChildren: 0.02,
        restDelta: 0.0001,
        restSpeed: 0.0001,
      },
    },
  };

  const childVariants = {
    initial: {
      y: 50,
      opacity: 0.0,
    },
    visible: {
      y: 0,
      opacity: [0, 1],
      transition: {
        ease: [0, 1, 1, 1],
        duration: 1.5,
        restDelta: 0.00001,
        restSpeed: 0.00001,
      },
    },
  };
  const { t } = useSanitizedTranslation();

  const imgs = [
    '/static/images/refresh/tiles/homepage/amc+.png', // 0
    '/static/images/refresh/tiles/homepage/apple tv+.png', // 1
    '/static/images/refresh/tiles/homepage/BET+.png', // 2
    '/static/images/refresh/tiles/homepage/britbox.png', // 3
    '/static/images/refresh/tiles/homepage/Custiosity stream.png', // 4
    '/static/images/refresh/tiles/homepage/DAZN.png',
    '/static/images/refresh/tiles/homepage/Disney+.png', //6
    '/static/images/refresh/tiles/homepage/ESPN+.png',
    '/static/images/refresh/tiles/homepage/youtube.png', // 8
    '/static/images/refresh/tiles/homepage/hulu.png',
    '/static/images/refresh/tiles/homepage/max.png', // 10
    '/static/images/refresh/tiles/homepage/Netflix.png', // 11
    '/static/images/refresh/tiles/homepage/pandora.png', // 12
    '/static/images/refresh/tiles/homepage/Paramount+.png', // 13
    '/static/images/refresh/tiles/homepage/peacock.png',
    '/static/images/refresh/tiles/homepage/pluto tv.png', // 15
    '/static/images/refresh/tiles/homepage/prime video.png', //16
    '/static/images/refresh/tiles/homepage/sling.png',
    '/static/images/refresh/tiles/homepage/Spotify.png', //18
    '/static/images/refresh/tiles/homepage/STARZ.png',
    '/static/images/refresh/tiles/homepage/tubi.png', // 20
    '/static/images/refresh/tiles/homepage/ViX.png', //21
    '/static/images/refresh/tiles/homepage/xumo PLAY.png', // 22
    '/static/images/refresh/tiles/homepage/Youtube TV.png',
    '/static/images/refresh/tiles/homepage/blippi.png', //24
    '/static/images/refresh/tiles/homepage/canela.png',
    '/static/images/refresh/tiles/homepage/cocomelon.png', //26
    '/static/images/refresh/tiles/homepage/fandango.png',
    '/static/images/refresh/tiles/homepage/filmrise.png', //28
    '/static/images/refresh/tiles/homepage/freevee.png',
    '/static/images/refresh/tiles/homepage/happykids.png', //30
    '/static/images/refresh/tiles/homepage/kidoodle.png',
    '/static/images/refresh/tiles/homepage/mlb.png', //32
    '/static/images/refresh/tiles/homepage/nba.png',
    '/static/images/refresh/tiles/homepage/nbc-sports.png', //34
    '/static/images/refresh/tiles/homepage/pbs-kids.png',
    '/static/images/refresh/tiles/homepage/plex.png', //36
    '/static/images/refresh/tiles/homepage/youtube-kids.png',
    '/static/images/refresh/tiles/homepage/fubo.png', //38
    '/static/images/refresh/tiles/homepage/fawesome.png',
    '/static/images/refresh/tiles/homepage/toon-goggles.png', //40
  ];

  const [tab, setTab] = useState(0);

  const tabDefs = [
    [
      [39, 11, 16, 9, 22, 11, 16, 9],
      [23, 10, 2, 15, 1, 10, 2, 15],
    ],
    [
      [38, 23, 17, 5, 14, 23, 17, 5],
      [7, 21, 34, 32, 33, 7, 21, 34, 32, 33],
    ],
    [
      [39, 8, 22, 28, 15, 8, 22, 28],
      [20, 27, 25, 36, 29, 20, 27, 25, 36, 29],
    ],
    [
      [22, 13, 14, 37, 6, 13, 14, 40],
      [35, 24, 26, 31, 30, 35, 24, 26, 31, 30],
    ],
  ];

  const tiles = tabDefs[tab];

  return (
    <motion.section ref={ref} className="relative z-40 flex w-full justify-center bg-white" data-testid={'text-section'}>
      <div className="wrapper">
        <div className={`${bg} py-16`}>
          <div className={`content-wrapper-x flex flex-col items-center justify-center pb-16`}>
            <StackedContent
              full
              center
              noOrphans
              content={[
                { heading: '<strong>Endless</strong> entertainment' },
                {
                  body: 'Pre-loaded with 250+ apps, no downloading necessary — just sign into your subscriptions or explore tons of free content to get started.',
                },
              ]}
            />

            <div className="type-body-base flex w-3/4 flex-wrap items-center justify-center pt-8 text-center tracking-tight max-lg:text-[18px] lg:space-x-2">
              <div
                onClick={() => setTab(0)}
                data-i18n="Movies & TV"
                className={
                  (tab === 0 ? 'weight-600 text-black outline-xumoTeal' : 'cursor-pointer text-xumoCharcoal outline-transparent hover:text-xumoBlack') +
                  ' no-shift rounded-full px-4 py-1 outline outline-2 max-lg:my-2'
                }
              >
                Movies & TV
              </div>
              <div
                onClick={() => setTab(1)}
                data-i18n="Live TV & Sports"
                className={
                  (tab === 1 ? 'weight-600 text-black outline-xumoTeal' : 'cursor-pointer text-xumoCharcoal outline-transparent hover:text-xumoBlack') +
                  ' no-shift rounded-full px-4 py-1 outline outline-2 max-lg:my-2'
                }
              >
                Live TV & Sports
              </div>
              <div
                onClick={() => setTab(2)}
                data-i18n="Free"
                className={
                  (tab === 2 ? 'weight-600 text-black outline-xumoTeal' : 'cursor-pointer text-xumoCharcoal outline-transparent hover:text-xumoBlack') +
                  ' no-shift rounded-full px-4 py-1 outline outline-2 max-lg:my-2'
                }
              >
                Free
              </div>
              <div
                onClick={() => setTab(3)}
                data-i18n="Family"
                className={
                  (tab === 3 ? 'weight-600 text-black outline-xumoTeal' : 'cursor-pointer text-xumoCharcoal outline-transparent hover:text-xumoBlack') +
                  ' no-shift rounded-full px-4 py-1 outline outline-2 max-lg:my-2'
                }
              >
                Family
              </div>
            </div>
          </div>

          <motion.div
            initial="initial"
            whileInView="visible"
            variants={variants}
            viewport={{ once: true, margin: '-20% 0% -20% 0%' }}
            className="absolute left-0 w-screen space-y-2 xl:space-y-4"
          >
            <motion.div style={{ x: scrollX }} className="relative flex space-x-2 xl:space-x-4">
              <div className="invisible inline-block aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
              <div className="space absolute -left-2 flex -translate-x-full space-x-2 xl:-left-4 xl:space-x-4">
                {tiles[0].map((item, index) => (
                  <motion.div
                    key={index + 'main'}
                    variants={childVariants}
                    className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                  >
                    <AnimatePresence>
                      <MotionImage
                        initial={{ opacity: 0, scale: 1.1 }}
                        animate={{ opacity: 1, scale: 1.0, transition: { ease: 'easeOut', restDelta: 0.00001, duration: 0.4, delay: 0.0075 * index } }}
                        exit={{ opacity: 0, transition: { delay: 0.5 } }}
                        key={tab + 'img' + index + imgs[item]}
                        src={imgs[item]}
                        alt=""
                        fill
                        className="object-cover"
                        sizes="40vw"
                      />
                    </AnimatePresence>
                  </motion.div>
                ))}
              </div>
              <motion.div className="absolute left-0 flex flex-row space-x-2 xl:space-x-4">
                {tiles[0].map((item, index) => (
                  <motion.div
                    key={'altmain' + index}
                    variants={childVariants}
                    className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                  >
                    <AnimatePresence>
                      <MotionImage
                        initial={{ opacity: 0, scale: 1.1 }}
                        animate={{
                          opacity: 1,
                          scale: 1.0,
                          transition: { ease: 'easeOut', restDelta: 0.00001, duration: 0.4, delay: 0.0075 * (index + tiles[0].length) },
                        }}
                        exit={{ opacity: 0, transition: { delay: 0.5 } }}
                        key={tab + 'img-main' + index + imgs[item]}
                        src={imgs[item]}
                        alt=""
                        fill
                        className="object-cover"
                        sizes="40vw"
                      />
                    </AnimatePresence>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>

            <motion.div style={{ x: scrollXAlt }} className="relative flex space-x-2 xl:space-x-4">
              <div className="invisible inline-block aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
              <div className="space absolute -left-2 flex -translate-x-full space-x-2 xl:-left-4 xl:space-x-4">
                {tiles[1].map((item, index) => (
                  <motion.div
                    key={index + 'other'}
                    variants={childVariants}
                    className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                  >
                    <AnimatePresence>
                      <MotionImage
                        initial={{ opacity: 0, scale: 1.1 }}
                        animate={{
                          opacity: 1,
                          scale: 1.0,
                          transition: { ease: 'easeOut', restDelta: 0.00001, duration: 0.4, delay: 0.0075 * (index + tiles[0].length * 2) },
                        }}
                        exit={{ opacity: 0, transition: { delay: 0.5 } }}
                        key={tab + 'img2' + index + imgs[item]}
                        src={imgs[item]}
                        alt=""
                        fill
                        className="object-cover"
                        sizes="40vw"
                      />
                    </AnimatePresence>
                  </motion.div>
                ))}
              </div>
              <motion.div className="absolute left-0 flex flex-row space-x-2 xl:space-x-4">
                {tiles[1].map((item, index) => (
                  <motion.div
                    key={'altother' + index}
                    variants={childVariants}
                    className="relative inline-block aspect-[16/7] w-[45vw] max-w-[400px] overflow-hidden rounded-xl shadow-xl xl:w-[20vw]"
                  >
                    <AnimatePresence>
                      <MotionImage
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          transition: { ease: 'easeOut', restDelta: 0.00001, duration: 0.4, delay: 0.0075 * (index + tiles[0].length * 3) },
                        }}
                        exit={{ opacity: 0, transition: { delay: 0.5 } }}
                        key={tab + 'img-main2' + index + imgs[item]}
                        src={imgs[item]}
                        alt=""
                        fill
                        className="object-cover"
                        sizes="40vw"
                      />
                    </AnimatePresence>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          </motion.div>

          <div className="invisible space-y-2 xl:space-y-4">
            <div className="aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
            <div className="aspect-[16/7] w-[45vw] max-w-[400px] rounded-xl shadow-xl xl:w-[20vw] " />
          </div>
          <div className="flex items-center justify-center pt-8">
            <Button href="#apps">Browse all Xumo TV apps</Button>
          </div>
        </div>
      </div>
    </motion.section>
  );
};
